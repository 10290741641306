import revive_payload_client_XhpJrSG0cm from "/builds/robustastudio/mazaya/karaca/node_modules/.pnpm/nuxt@3.12.4_@types+node@22.0.0_eslint@8.57.0_rollup@4.19.1_typescript@5.5.4_vite@5.3.5_vue-tsc@2.0.29/node_modules/nuxt/dist/app/plugins/revive-payload.client.js";
import unhead_9bK2jvO6XC from "/builds/robustastudio/mazaya/karaca/node_modules/.pnpm/nuxt@3.12.4_@types+node@22.0.0_eslint@8.57.0_rollup@4.19.1_typescript@5.5.4_vite@5.3.5_vue-tsc@2.0.29/node_modules/nuxt/dist/head/runtime/plugins/unhead.js";
import router_tmv2FB7xI9 from "/builds/robustastudio/mazaya/karaca/node_modules/.pnpm/nuxt@3.12.4_@types+node@22.0.0_eslint@8.57.0_rollup@4.19.1_typescript@5.5.4_vite@5.3.5_vue-tsc@2.0.29/node_modules/nuxt/dist/pages/runtime/plugins/router.js";
import payload_client_vmjiFUoUY4 from "/builds/robustastudio/mazaya/karaca/node_modules/.pnpm/nuxt@3.12.4_@types+node@22.0.0_eslint@8.57.0_rollup@4.19.1_typescript@5.5.4_vite@5.3.5_vue-tsc@2.0.29/node_modules/nuxt/dist/app/plugins/payload.client.js";
import navigation_repaint_client_uCQK6FwMF8 from "/builds/robustastudio/mazaya/karaca/node_modules/.pnpm/nuxt@3.12.4_@types+node@22.0.0_eslint@8.57.0_rollup@4.19.1_typescript@5.5.4_vite@5.3.5_vue-tsc@2.0.29/node_modules/nuxt/dist/app/plugins/navigation-repaint.client.js";
import check_outdated_build_client_qWvgL2Zsiz from "/builds/robustastudio/mazaya/karaca/node_modules/.pnpm/nuxt@3.12.4_@types+node@22.0.0_eslint@8.57.0_rollup@4.19.1_typescript@5.5.4_vite@5.3.5_vue-tsc@2.0.29/node_modules/nuxt/dist/app/plugins/check-outdated-build.client.js";
import chunk_reload_client_Om3FytuIey from "/builds/robustastudio/mazaya/karaca/node_modules/.pnpm/nuxt@3.12.4_@types+node@22.0.0_eslint@8.57.0_rollup@4.19.1_typescript@5.5.4_vite@5.3.5_vue-tsc@2.0.29/node_modules/nuxt/dist/app/plugins/chunk-reload.client.js";
import components_plugin_KR1HBZs4kY from "/builds/robustastudio/mazaya/karaca/.nuxt/components.plugin.mjs";
import prefetch_client_uipvnkubsL from "/builds/robustastudio/mazaya/karaca/node_modules/.pnpm/nuxt@3.12.4_@types+node@22.0.0_eslint@8.57.0_rollup@4.19.1_typescript@5.5.4_vite@5.3.5_vue-tsc@2.0.29/node_modules/nuxt/dist/pages/runtime/plugins/prefetch.client.js";
import pwa_icons_egkC0bMglq from "/builds/robustastudio/mazaya/karaca/node_modules/.pnpm/@vite-pwa+nuxt@0.6.0_@vite-pwa+assets-generator@0.2.4_rollup@4.19.1_vite@5.3.5_workbox-build@7.1.1_workbox-window@7.1.0/node_modules/@vite-pwa/nuxt/dist/runtime/plugins/pwa-icons.mjs";
import pwa_client_H00QI9SMTz from "/builds/robustastudio/mazaya/karaca/node_modules/.pnpm/@vite-pwa+nuxt@0.6.0_@vite-pwa+assets-generator@0.2.4_rollup@4.19.1_vite@5.3.5_workbox-build@7.1.1_workbox-window@7.1.0/node_modules/@vite-pwa/nuxt/dist/runtime/plugins/pwa.client.mjs";
import nuxt_plugin_nOtw1XE05D from "/builds/robustastudio/mazaya/karaca/node_modules/.pnpm/nuxt-delay-hydration@1.3.5_rollup@4.19.1/node_modules/nuxt-delay-hydration/dist/runtime/nuxt-plugin.js";
import plugin_vJMX8vIrJ6 from "/builds/robustastudio/mazaya/karaca/node_modules/.pnpm/nuxt-jsonld@2.0.8_typescript@5.5.4/node_modules/nuxt-jsonld/dist/runtime/plugin.js";
import gtm_client_CTAADNhzWi from "/builds/robustastudio/mazaya/karaca/node_modules/.pnpm/@robustastudio+e-commerce@1.43.0-1.42.0-branching.1_rollup@4.19.1_vite@5.3.5_vue@3.4.34/node_modules/@robustastudio/e-commerce/dist/runtime/module-plugins/gtm.client.mjs";
import ssr_caching_3kH4MGNl7j from "/builds/robustastudio/mazaya/karaca/node_modules/.pnpm/@robustastudio+e-commerce@1.43.0-1.42.0-branching.1_rollup@4.19.1_vite@5.3.5_vue@3.4.34/node_modules/@robustastudio/e-commerce/dist/runtime/module-plugins/ssr-caching.mjs";
import i18n_FV9haaZg2f from "/builds/robustastudio/mazaya/karaca/node_modules/.pnpm/@nuxtjs+i18n@8.2.0_rollup@4.19.1_vue@3.4.34/node_modules/@nuxtjs/i18n/dist/runtime/plugins/i18n.mjs";
import loading_GxQ2Z9Goxa from "/builds/robustastudio/mazaya/karaca/plugins/loading.ts";
import purify_html_Hz9hnAad5c from "/builds/robustastudio/mazaya/karaca/plugins/purify-html.ts";
import setup_0lDNjxjaWh from "/builds/robustastudio/mazaya/karaca/plugins/setup.ts";
export default [
  revive_payload_client_XhpJrSG0cm,
  unhead_9bK2jvO6XC,
  router_tmv2FB7xI9,
  payload_client_vmjiFUoUY4,
  navigation_repaint_client_uCQK6FwMF8,
  check_outdated_build_client_qWvgL2Zsiz,
  chunk_reload_client_Om3FytuIey,
  components_plugin_KR1HBZs4kY,
  prefetch_client_uipvnkubsL,
  pwa_icons_egkC0bMglq,
  pwa_client_H00QI9SMTz,
  nuxt_plugin_nOtw1XE05D,
  plugin_vJMX8vIrJ6,
  gtm_client_CTAADNhzWi,
  ssr_caching_3kH4MGNl7j,
  i18n_FV9haaZg2f,
  loading_GxQ2Z9Goxa,
  purify_html_Hz9hnAad5c,
  setup_0lDNjxjaWh
]