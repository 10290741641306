import { default as icons_45pageauLp9CtG1uMeta } from "/builds/robustastudio/mazaya/karaca/node_modules/.pnpm/@nuxtjs+svg-sprite@1.0.2_rollup@4.19.1/node_modules/@nuxtjs/svg-sprite/dist/runtime/components/icons-page.vue?macro=true";
import { default as index78369IU5AIMeta } from "/builds/robustastudio/mazaya/karaca/pages/[...slug]/index.vue?macro=true";
import { default as aboutFUUTW7W6WbMeta } from "/builds/robustastudio/mazaya/karaca/pages/about.vue?macro=true";
import { default as edit8CiFSeUje7Meta } from "/builds/robustastudio/mazaya/karaca/pages/account/addresses/[id]/edit.vue?macro=true";
import { default as indexpNIunNMUiNMeta } from "/builds/robustastudio/mazaya/karaca/pages/account/addresses/index.vue?macro=true";
import { default as newdGfjaV1ZCDMeta } from "/builds/robustastudio/mazaya/karaca/pages/account/addresses/new.vue?macro=true";
import { default as emailXCKBYyKx1wMeta } from "/builds/robustastudio/mazaya/karaca/pages/account/edit/email.vue?macro=true";
import { default as indexE89js5FgxWMeta } from "/builds/robustastudio/mazaya/karaca/pages/account/edit/index.vue?macro=true";
import { default as passwordwkgJ7wxKf1Meta } from "/builds/robustastudio/mazaya/karaca/pages/account/edit/password.vue?macro=true";
import { default as indexZEYOyykR6QMeta } from "/builds/robustastudio/mazaya/karaca/pages/account/index.vue?macro=true";
import { default as _91id_93Z6rYvSJ9UsMeta } from "/builds/robustastudio/mazaya/karaca/pages/account/orders/[id].vue?macro=true";
import { default as indexLBYGiCX7fMMeta } from "/builds/robustastudio/mazaya/karaca/pages/account/orders/index.vue?macro=true";
import { default as wishlist55jEQYMISMMeta } from "/builds/robustastudio/mazaya/karaca/pages/account/wishlist.vue?macro=true";
import { default as accountu1W9ptokkkMeta } from "/builds/robustastudio/mazaya/karaca/pages/account.vue?macro=true";
import { default as recoverzuKyVAz6uKMeta } from "/builds/robustastudio/mazaya/karaca/pages/auth/recover.vue?macro=true";
import { default as _91slug_93gnNaJ1n7E6Meta } from "/builds/robustastudio/mazaya/karaca/pages/brands/[slug].vue?macro=true";
import { default as indexC0WAkvRMwZMeta } from "/builds/robustastudio/mazaya/karaca/pages/brands/index.vue?macro=true";
import { default as cartiuMpSYVjOeMeta } from "/builds/robustastudio/mazaya/karaca/pages/cart.vue?macro=true";
import { default as guest_45shippingNKkHudcRyAMeta } from "/builds/robustastudio/mazaya/karaca/pages/checkout/guest-shipping.vue?macro=true";
import { default as payb0b2tiQEINMeta } from "/builds/robustastudio/mazaya/karaca/pages/checkout/pay.vue?macro=true";
import { default as shippingmcWWqCDbtiMeta } from "/builds/robustastudio/mazaya/karaca/pages/checkout/shipping.vue?macro=true";
import { default as contactjg0QEJUr7wMeta } from "/builds/robustastudio/mazaya/karaca/pages/contact.vue?macro=true";
import { default as dealsDaBMKTMF5lMeta } from "/builds/robustastudio/mazaya/karaca/pages/deals.vue?macro=true";
import { default as errorrX8hp1zhKVMeta } from "/builds/robustastudio/mazaya/karaca/pages/error.vue?macro=true";
import { default as _91slug_939IGSj0jJsyMeta } from "/builds/robustastudio/mazaya/karaca/pages/faqs/[slug].vue?macro=true";
import { default as indexqLJGL0hzT4Meta } from "/builds/robustastudio/mazaya/karaca/pages/faqs/index.vue?macro=true";
import { default as successCe5T36e1x0Meta } from "/builds/robustastudio/mazaya/karaca/pages/guest-order/[id]/success.vue?macro=true";
import { default as health_45checkbBMMjvOQajMeta } from "/builds/robustastudio/mazaya/karaca/pages/health-check.vue?macro=true";
import { default as indexjktGkayNHFMeta } from "/builds/robustastudio/mazaya/karaca/pages/index.vue?macro=true";
import { default as logingXsEeb6y0iMeta } from "/builds/robustastudio/mazaya/karaca/pages/login.vue?macro=true";
import { default as new_45inPV8vX1F8IyMeta } from "/builds/robustastudio/mazaya/karaca/pages/new-in.vue?macro=true";
import { default as create_45reviewJcqBLrV7eqMeta } from "/builds/robustastudio/mazaya/karaca/pages/orders/[id]/create-review.vue?macro=true";
import { default as success4YoNFdW5cvMeta } from "/builds/robustastudio/mazaya/karaca/pages/orders/[id]/success.vue?macro=true";
import { default as privacy_45policyHeGUpC80gdMeta } from "/builds/robustastudio/mazaya/karaca/pages/privacy-policy.vue?macro=true";
import { default as registerhdUDn2qMw8Meta } from "/builds/robustastudio/mazaya/karaca/pages/register.vue?macro=true";
import { default as _91name_93UhqwMWlPw0Meta } from "/builds/robustastudio/mazaya/karaca/pages/search/[name].vue?macro=true";
import { default as searchsGeX84qHQKMeta } from "/builds/robustastudio/mazaya/karaca/pages/search.vue?macro=true";
import { default as terms_45and_45conditionsYmxeN1wSC0Meta } from "/builds/robustastudio/mazaya/karaca/pages/terms-and-conditions.vue?macro=true";
import { default as test2tyK8dcY9tMeta } from "/builds/robustastudio/mazaya/karaca/pages/test.vue?macro=true";
export default [
  {
    name: "icons-page",
    path: "/_icons",
    meta: {"layout":"svg-sprite"},
    component: () => import("/builds/robustastudio/mazaya/karaca/node_modules/.pnpm/@nuxtjs+svg-sprite@1.0.2_rollup@4.19.1/node_modules/@nuxtjs/svg-sprite/dist/runtime/components/icons-page.vue").then(m => m.default || m)
  },
  {
    name: "slug___ar",
    path: "/ar/:slug(.*)*",
    meta: index78369IU5AIMeta || {},
    component: () => import("/builds/robustastudio/mazaya/karaca/pages/[...slug]/index.vue").then(m => m.default || m)
  },
  {
    name: "slug___en",
    path: "/en/:slug(.*)*",
    meta: index78369IU5AIMeta || {},
    component: () => import("/builds/robustastudio/mazaya/karaca/pages/[...slug]/index.vue").then(m => m.default || m)
  },
  {
    name: "about___ar",
    path: "/ar/about",
    component: () => import("/builds/robustastudio/mazaya/karaca/pages/about.vue").then(m => m.default || m)
  },
  {
    name: "about___en",
    path: "/en/about",
    component: () => import("/builds/robustastudio/mazaya/karaca/pages/about.vue").then(m => m.default || m)
  },
  {
    name: accountu1W9ptokkkMeta?.name,
    path: "/ar/account",
    component: () => import("/builds/robustastudio/mazaya/karaca/pages/account.vue").then(m => m.default || m),
    children: [
  {
    name: "account-addresses-id-edit___ar",
    path: "addresses/:id()/edit",
    component: () => import("/builds/robustastudio/mazaya/karaca/pages/account/addresses/[id]/edit.vue").then(m => m.default || m)
  },
  {
    name: "account-addresses___ar",
    path: "addresses",
    component: () => import("/builds/robustastudio/mazaya/karaca/pages/account/addresses/index.vue").then(m => m.default || m)
  },
  {
    name: "account-addresses-new___ar",
    path: "addresses/new",
    component: () => import("/builds/robustastudio/mazaya/karaca/pages/account/addresses/new.vue").then(m => m.default || m)
  },
  {
    name: "account-edit-email___ar",
    path: "edit/email",
    component: () => import("/builds/robustastudio/mazaya/karaca/pages/account/edit/email.vue").then(m => m.default || m)
  },
  {
    name: "account-edit___ar",
    path: "edit",
    component: () => import("/builds/robustastudio/mazaya/karaca/pages/account/edit/index.vue").then(m => m.default || m)
  },
  {
    name: "account-edit-password___ar",
    path: "edit/password",
    component: () => import("/builds/robustastudio/mazaya/karaca/pages/account/edit/password.vue").then(m => m.default || m)
  },
  {
    name: "account___ar",
    path: "",
    component: () => import("/builds/robustastudio/mazaya/karaca/pages/account/index.vue").then(m => m.default || m)
  },
  {
    name: "account-orders-id___ar",
    path: "orders/:id()",
    component: () => import("/builds/robustastudio/mazaya/karaca/pages/account/orders/[id].vue").then(m => m.default || m)
  },
  {
    name: "account-orders___ar",
    path: "orders",
    component: () => import("/builds/robustastudio/mazaya/karaca/pages/account/orders/index.vue").then(m => m.default || m)
  },
  {
    name: "account-wishlist___ar",
    path: "wishlist",
    component: () => import("/builds/robustastudio/mazaya/karaca/pages/account/wishlist.vue").then(m => m.default || m)
  }
]
  },
  {
    name: accountu1W9ptokkkMeta?.name,
    path: "/en/account",
    component: () => import("/builds/robustastudio/mazaya/karaca/pages/account.vue").then(m => m.default || m),
    children: [
  {
    name: "account-addresses-id-edit___en",
    path: "addresses/:id()/edit",
    component: () => import("/builds/robustastudio/mazaya/karaca/pages/account/addresses/[id]/edit.vue").then(m => m.default || m)
  },
  {
    name: "account-addresses___en",
    path: "addresses",
    component: () => import("/builds/robustastudio/mazaya/karaca/pages/account/addresses/index.vue").then(m => m.default || m)
  },
  {
    name: "account-addresses-new___en",
    path: "addresses/new",
    component: () => import("/builds/robustastudio/mazaya/karaca/pages/account/addresses/new.vue").then(m => m.default || m)
  },
  {
    name: "account-edit-email___en",
    path: "edit/email",
    component: () => import("/builds/robustastudio/mazaya/karaca/pages/account/edit/email.vue").then(m => m.default || m)
  },
  {
    name: "account-edit___en",
    path: "edit",
    component: () => import("/builds/robustastudio/mazaya/karaca/pages/account/edit/index.vue").then(m => m.default || m)
  },
  {
    name: "account-edit-password___en",
    path: "edit/password",
    component: () => import("/builds/robustastudio/mazaya/karaca/pages/account/edit/password.vue").then(m => m.default || m)
  },
  {
    name: "account___en",
    path: "",
    component: () => import("/builds/robustastudio/mazaya/karaca/pages/account/index.vue").then(m => m.default || m)
  },
  {
    name: "account-orders-id___en",
    path: "orders/:id()",
    component: () => import("/builds/robustastudio/mazaya/karaca/pages/account/orders/[id].vue").then(m => m.default || m)
  },
  {
    name: "account-orders___en",
    path: "orders",
    component: () => import("/builds/robustastudio/mazaya/karaca/pages/account/orders/index.vue").then(m => m.default || m)
  },
  {
    name: "account-wishlist___en",
    path: "wishlist",
    component: () => import("/builds/robustastudio/mazaya/karaca/pages/account/wishlist.vue").then(m => m.default || m)
  }
]
  },
  {
    name: "auth-recover___ar",
    path: "/ar/auth/recover",
    meta: recoverzuKyVAz6uKMeta || {},
    component: () => import("/builds/robustastudio/mazaya/karaca/pages/auth/recover.vue").then(m => m.default || m)
  },
  {
    name: "auth-recover___en",
    path: "/en/auth/recover",
    meta: recoverzuKyVAz6uKMeta || {},
    component: () => import("/builds/robustastudio/mazaya/karaca/pages/auth/recover.vue").then(m => m.default || m)
  },
  {
    name: "brands-slug___ar",
    path: "/ar/brands/:slug()",
    component: () => import("/builds/robustastudio/mazaya/karaca/pages/brands/[slug].vue").then(m => m.default || m)
  },
  {
    name: "brands-slug___en",
    path: "/en/brands/:slug()",
    component: () => import("/builds/robustastudio/mazaya/karaca/pages/brands/[slug].vue").then(m => m.default || m)
  },
  {
    name: "brands___ar",
    path: "/ar/brands",
    component: () => import("/builds/robustastudio/mazaya/karaca/pages/brands/index.vue").then(m => m.default || m)
  },
  {
    name: "brands___en",
    path: "/en/brands",
    component: () => import("/builds/robustastudio/mazaya/karaca/pages/brands/index.vue").then(m => m.default || m)
  },
  {
    name: "cart___ar",
    path: "/ar/cart",
    component: () => import("/builds/robustastudio/mazaya/karaca/pages/cart.vue").then(m => m.default || m)
  },
  {
    name: "cart___en",
    path: "/en/cart",
    component: () => import("/builds/robustastudio/mazaya/karaca/pages/cart.vue").then(m => m.default || m)
  },
  {
    name: "checkout-guest-shipping___ar",
    path: "/ar/checkout/guest-shipping",
    meta: guest_45shippingNKkHudcRyAMeta || {},
    component: () => import("/builds/robustastudio/mazaya/karaca/pages/checkout/guest-shipping.vue").then(m => m.default || m)
  },
  {
    name: "checkout-guest-shipping___en",
    path: "/en/checkout/guest-shipping",
    meta: guest_45shippingNKkHudcRyAMeta || {},
    component: () => import("/builds/robustastudio/mazaya/karaca/pages/checkout/guest-shipping.vue").then(m => m.default || m)
  },
  {
    name: "checkout-pay___ar",
    path: "/ar/checkout/pay",
    meta: payb0b2tiQEINMeta || {},
    component: () => import("/builds/robustastudio/mazaya/karaca/pages/checkout/pay.vue").then(m => m.default || m)
  },
  {
    name: "checkout-pay___en",
    path: "/en/checkout/pay",
    meta: payb0b2tiQEINMeta || {},
    component: () => import("/builds/robustastudio/mazaya/karaca/pages/checkout/pay.vue").then(m => m.default || m)
  },
  {
    name: "checkout-shipping___ar",
    path: "/ar/checkout/shipping",
    meta: shippingmcWWqCDbtiMeta || {},
    component: () => import("/builds/robustastudio/mazaya/karaca/pages/checkout/shipping.vue").then(m => m.default || m)
  },
  {
    name: "checkout-shipping___en",
    path: "/en/checkout/shipping",
    meta: shippingmcWWqCDbtiMeta || {},
    component: () => import("/builds/robustastudio/mazaya/karaca/pages/checkout/shipping.vue").then(m => m.default || m)
  },
  {
    name: "contact___ar",
    path: "/ar/contact",
    component: () => import("/builds/robustastudio/mazaya/karaca/pages/contact.vue").then(m => m.default || m)
  },
  {
    name: "contact___en",
    path: "/en/contact",
    component: () => import("/builds/robustastudio/mazaya/karaca/pages/contact.vue").then(m => m.default || m)
  },
  {
    name: "deals___ar",
    path: "/ar/deals",
    component: () => import("/builds/robustastudio/mazaya/karaca/pages/deals.vue").then(m => m.default || m)
  },
  {
    name: "deals___en",
    path: "/en/deals",
    component: () => import("/builds/robustastudio/mazaya/karaca/pages/deals.vue").then(m => m.default || m)
  },
  {
    name: "error___ar",
    path: "/ar/error",
    component: () => import("/builds/robustastudio/mazaya/karaca/pages/error.vue").then(m => m.default || m)
  },
  {
    name: "error___en",
    path: "/en/error",
    component: () => import("/builds/robustastudio/mazaya/karaca/pages/error.vue").then(m => m.default || m)
  },
  {
    name: "faqs-slug___ar",
    path: "/ar/faqs/:slug()",
    component: () => import("/builds/robustastudio/mazaya/karaca/pages/faqs/[slug].vue").then(m => m.default || m)
  },
  {
    name: "faqs-slug___en",
    path: "/en/faqs/:slug()",
    component: () => import("/builds/robustastudio/mazaya/karaca/pages/faqs/[slug].vue").then(m => m.default || m)
  },
  {
    name: "faqs___ar",
    path: "/ar/faqs",
    component: () => import("/builds/robustastudio/mazaya/karaca/pages/faqs/index.vue").then(m => m.default || m)
  },
  {
    name: "faqs___en",
    path: "/en/faqs",
    component: () => import("/builds/robustastudio/mazaya/karaca/pages/faqs/index.vue").then(m => m.default || m)
  },
  {
    name: "guest-order-id-success___ar",
    path: "/ar/guest-order/:id()/success",
    meta: successCe5T36e1x0Meta || {},
    component: () => import("/builds/robustastudio/mazaya/karaca/pages/guest-order/[id]/success.vue").then(m => m.default || m)
  },
  {
    name: "guest-order-id-success___en",
    path: "/en/guest-order/:id()/success",
    meta: successCe5T36e1x0Meta || {},
    component: () => import("/builds/robustastudio/mazaya/karaca/pages/guest-order/[id]/success.vue").then(m => m.default || m)
  },
  {
    name: "health-check___ar",
    path: "/ar/health-check",
    meta: health_45checkbBMMjvOQajMeta || {},
    component: () => import("/builds/robustastudio/mazaya/karaca/pages/health-check.vue").then(m => m.default || m)
  },
  {
    name: "health-check___en",
    path: "/en/health-check",
    meta: health_45checkbBMMjvOQajMeta || {},
    component: () => import("/builds/robustastudio/mazaya/karaca/pages/health-check.vue").then(m => m.default || m)
  },
  {
    name: "index___ar",
    path: "/ar",
    component: () => import("/builds/robustastudio/mazaya/karaca/pages/index.vue").then(m => m.default || m)
  },
  {
    name: "index___en",
    path: "/en",
    component: () => import("/builds/robustastudio/mazaya/karaca/pages/index.vue").then(m => m.default || m)
  },
  {
    name: "login___ar",
    path: "/ar/login",
    meta: logingXsEeb6y0iMeta || {},
    component: () => import("/builds/robustastudio/mazaya/karaca/pages/login.vue").then(m => m.default || m)
  },
  {
    name: "login___en",
    path: "/en/login",
    meta: logingXsEeb6y0iMeta || {},
    component: () => import("/builds/robustastudio/mazaya/karaca/pages/login.vue").then(m => m.default || m)
  },
  {
    name: "new-in___ar",
    path: "/ar/new-in",
    component: () => import("/builds/robustastudio/mazaya/karaca/pages/new-in.vue").then(m => m.default || m)
  },
  {
    name: "new-in___en",
    path: "/en/new-in",
    component: () => import("/builds/robustastudio/mazaya/karaca/pages/new-in.vue").then(m => m.default || m)
  },
  {
    name: "orders-id-create-review___ar",
    path: "/ar/orders/:id()/create-review",
    component: () => import("/builds/robustastudio/mazaya/karaca/pages/orders/[id]/create-review.vue").then(m => m.default || m)
  },
  {
    name: "orders-id-create-review___en",
    path: "/en/orders/:id()/create-review",
    component: () => import("/builds/robustastudio/mazaya/karaca/pages/orders/[id]/create-review.vue").then(m => m.default || m)
  },
  {
    name: "orders-id-success___ar",
    path: "/ar/orders/:id()/success",
    meta: success4YoNFdW5cvMeta || {},
    component: () => import("/builds/robustastudio/mazaya/karaca/pages/orders/[id]/success.vue").then(m => m.default || m)
  },
  {
    name: "orders-id-success___en",
    path: "/en/orders/:id()/success",
    meta: success4YoNFdW5cvMeta || {},
    component: () => import("/builds/robustastudio/mazaya/karaca/pages/orders/[id]/success.vue").then(m => m.default || m)
  },
  {
    name: "privacy-policy___ar",
    path: "/ar/privacy-policy",
    component: () => import("/builds/robustastudio/mazaya/karaca/pages/privacy-policy.vue").then(m => m.default || m)
  },
  {
    name: "privacy-policy___en",
    path: "/en/privacy-policy",
    component: () => import("/builds/robustastudio/mazaya/karaca/pages/privacy-policy.vue").then(m => m.default || m)
  },
  {
    name: "register___ar",
    path: "/ar/register",
    meta: registerhdUDn2qMw8Meta || {},
    component: () => import("/builds/robustastudio/mazaya/karaca/pages/register.vue").then(m => m.default || m)
  },
  {
    name: "register___en",
    path: "/en/register",
    meta: registerhdUDn2qMw8Meta || {},
    component: () => import("/builds/robustastudio/mazaya/karaca/pages/register.vue").then(m => m.default || m)
  },
  {
    name: "search___ar",
    path: "/ar/search",
    component: () => import("/builds/robustastudio/mazaya/karaca/pages/search.vue").then(m => m.default || m),
    children: [
  {
    name: "search-name___ar",
    path: ":name()",
    component: () => import("/builds/robustastudio/mazaya/karaca/pages/search/[name].vue").then(m => m.default || m)
  }
]
  },
  {
    name: "search___en",
    path: "/en/search",
    component: () => import("/builds/robustastudio/mazaya/karaca/pages/search.vue").then(m => m.default || m),
    children: [
  {
    name: "search-name___en",
    path: ":name()",
    component: () => import("/builds/robustastudio/mazaya/karaca/pages/search/[name].vue").then(m => m.default || m)
  }
]
  },
  {
    name: "terms-and-conditions___ar",
    path: "/ar/terms-and-conditions",
    component: () => import("/builds/robustastudio/mazaya/karaca/pages/terms-and-conditions.vue").then(m => m.default || m)
  },
  {
    name: "terms-and-conditions___en",
    path: "/en/terms-and-conditions",
    component: () => import("/builds/robustastudio/mazaya/karaca/pages/terms-and-conditions.vue").then(m => m.default || m)
  },
  {
    name: "test___ar",
    path: "/ar/test",
    meta: test2tyK8dcY9tMeta || {},
    component: () => import("/builds/robustastudio/mazaya/karaca/pages/test.vue").then(m => m.default || m)
  },
  {
    name: "test___en",
    path: "/en/test",
    meta: test2tyK8dcY9tMeta || {},
    component: () => import("/builds/robustastudio/mazaya/karaca/pages/test.vue").then(m => m.default || m)
  }
]